import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons/faChartNetwork';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faFileSearch } from '@fortawesome/pro-light-svg-icons/faFileSearch';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faHomeAlt } from '@fortawesome/pro-light-svg-icons/faHomeAlt';
import { faIdBadge } from '@fortawesome/pro-light-svg-icons/faIdBadge';
import { faGavel } from '@fortawesome/pro-light-svg-icons/faGavel';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { faBuildingColumns } from '@fortawesome/pro-light-svg-icons/faBuildingColumns';
import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';

import {
  agreementsPageRoute,
  auditsPageRoute,
  calendarRoute,
  companyProfileRoute,
  complianceFrameworksRoute,
  complianceTasksRoute,
  complianceTestsRoute,
  controlsPageRoute,
  dataRoomRoute,
  policiesPageRoute,
  policyRoutes,
  trusthubAdminPageRoute,
} from '../Routes/Routes';
import { MainMenuItemConfiguration } from './MainMenuItemConfiguration';
import { AccessObject } from '../../types/accessObject';
import { useCanFeature } from '../../functions/access';
import {
  FrameworkModel,
  Subscription,
  VendorProductIndex,
  VendorProductIndexFeatures,
} from '../../swagger';
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons/faLockKeyhole';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';

export const isProductPaid = (
  feature: keyof VendorProductIndexFeatures,
  featuresState: VendorProductIndexFeatures
): boolean => {
  // for guest user ( for example if assessment outside adoptech and he will create an account) featureState during signup can be null
  return featuresState ? featuresState[feature] : false;
};

export const isFrameworkProductPaid = (
  framework: FrameworkModel,
  products: VendorProductIndex
) => {
  if (!framework.id) return false; // if framework was not generated on the backend for old customers
  return (products.complianceFrameworks || []).find(
    product => product.identifier === framework.identifier
  )?.available;
};

export const mainMenuConfig = (
  activeSubscription: Subscription,
  options?: {
    fromSignup: boolean;
    isPaidAssessments: boolean;
  }
): MainMenuItemConfiguration[] => {
  const noSubscription = !activeSubscription;
  const { fromSignup, isPaidAssessments } = options || {};

  const canFeature = useCanFeature;
  const disabledMenuItem = (item: keyof typeof AccessObject) => {
    return !canFeature(AccessObject[item]);
  };

  return [
    {
      icon: faHomeAlt,
      label: 'Dashboard',
      route: '/r/dashboard',
      disabledExpression: _ =>
        disabledMenuItem('vendor_dashboard') || noSubscription,
    },

    {
      icon: faCalendarCheck,
      label: 'Actions & Events',
      route: calendarRoute,
      disabledExpression: _ => disabledMenuItem('calendar') || noSubscription,
    },
    {
      label: 'GOVERNANCE',
      childItems: [
        {
          icon: faBookAlt,
          label: 'Policies & Docs',
          route: policiesPageRoute,
          routes: policyRoutes,
          disabledExpression: _ => noSubscription,
        },
        {
          icon: faFileContract,
          label: 'Agreements',
          route: agreementsPageRoute,
          disabledExpression: _ =>
            disabledMenuItem('agreements') || noSubscription,
        },
        {
          icon: faUserFriends,
          label: 'People',
          route: '/r/governance/people',
          disabledExpression: _ => disabledMenuItem('people') || noSubscription,
        },
      ],
    },
    {
      label: 'RISK MANAGEMENT',
      childItems: [
        {
          icon: faFileSearch,
          label: 'Assessments',
          route: '/r/riskManagement/assessments',
          disabledExpression: _ => {
            // add const below to avoid condition hook render error: "Rendered more hooks than during the previous render"
            const disabled = disabledMenuItem('assessments');
            if (isPaidAssessments) return false; // if has been paid ( >= professional ) -> always enable
            return disabled; // if have incoming assessment -> enable else disable
          },
        },
        {
          icon: faBuildingColumns,

          label: 'PESTEL',
          route: '/r/riskManagement/pestel',
          disabledExpression: _ => disabledMenuItem('pestel') || noSubscription,
        },
        {
          icon: faGavel,
          label: 'Legal register',
          route: '/r/riskManagement/legalRegister',
          disabledExpression: _ =>
            disabledMenuItem('legal_register') || noSubscription,
        },
        {
          icon: faIdBadge,
          label: 'Vendor register',
          route: '/r/riskManagement/vendorRegister',
          disabledExpression: _ =>
            disabledMenuItem('vendor_register') || noSubscription,
        },
        {
          icon: faBallotCheck,
          label: 'Risk register',
          route: '/r/riskManagement/riskRegister',
          disabledExpression: _ =>
            disabledMenuItem('risk_register') || noSubscription,
        },
      ],
    },
    {
      label: 'COMPLIANCE',
      childItems: [
        {
          icon: faFileCertificate,
          label: 'Frameworks',
          route: complianceFrameworksRoute,
          disabledExpression: _ =>
            disabledMenuItem('frameworks') || noSubscription,
        },
        {
          icon: faPlayCircle,
          label: 'Controls',
          route: controlsPageRoute,
          disabledExpression: _ =>
            disabledMenuItem('controls') || noSubscription,
        },
        {
          icon: faCheckCircle,
          label: 'Checks',
          route: complianceTasksRoute,
          disabledExpression: _ => disabledMenuItem('tasks') || noSubscription,
        },
        {
          icon: faClipboardList,
          label: 'Test results',
          route: complianceTestsRoute,
          disabledExpression: _ => disabledMenuItem('tasks') || noSubscription,
        },
        {
          icon: faBallotCheck,
          label: 'Audits',
          route: auditsPageRoute,
          disabledExpression: _ => disabledMenuItem('audits') || noSubscription,
        },
        {
          disabledExpression: _ => noSubscription,
          icon: faServer,
          label: 'Data Room',
          route: dataRoomRoute,
        },
      ],
    },
    {
      label: 'COMPANY INFORMATION',
      childItems: [
        {
          icon: faBuilding,
          label: 'Profile',
          route: companyProfileRoute,
          disabledExpression: _ => noSubscription,
        },
        {
          icon: faChartNetwork,
          label: 'Apps & Integrations',
          route: '/r/companyInformation/integrations',
          disabledExpression: _ =>
            disabledMenuItem('integrations') || noSubscription,
        },
        {
          icon: faHandshake,
          label: 'TrustHub',
          route: trusthubAdminPageRoute,
          disabledExpression: _ =>
            disabledMenuItem('trusthub') || noSubscription,
        },
        {
          icon: faClipboardCheck,
          label: 'Checklists',
          route: '/r/companyInformation/checklists',
          disabledExpression: _ =>
            disabledMenuItem('checklists') || noSubscription,
        },
      ],
    },
  ];
};
